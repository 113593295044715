import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthtakeService } from 'src/app/core/services/authtake.service';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  IMAGE_URL:any;
  Isopen:boolean = false;
  isDropdownOpen:boolean = false;
  element;
  configData;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  textDir:string = 'ltr' ;
  searchResults:any[] = [];
  fullName:string;
  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: AuthenticationService,
              private authFackservice: AuthtakeService,
              private apiService: ApiService,
              public languageService: LanguageService,
              public translate: TranslateService,
              public _cookiesService: CookieService,public authTakeService : AuthtakeService) {
  }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' , dir : 'ltr'},
    { text: 'Hebrew', flag: 'assets/images/flags/israel.png', lang: 'il' , dir:'rtl'}
  ];
 
  openMobileMenu: boolean;
  toggleSearch:boolean = false;
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  

  ngOnInit() {
    this.IMAGE_URL = ApiService.IMAGE_URL;
    this.openMobileMenu = false;
    this.element = document.documentElement;
    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };
    console.log(this.authTakeService.currentUserValue);
    
    this.fullName = this.authTakeService.currentUserValue.fullname.split(" ")[0];

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    debugger;
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
      // this.fullName = this.authTakeService.currentUserValue.fullname.split(" ")[0];
    } else {
      this.flagvalue = val.map(element => element.flag);
      if(val[0].text === "Hebrew" ){
         if(this.authTakeService.currentUserValue.fullname_il != null 
          && this.authTakeService.currentUserValue.fullname_il != "" 
          && this.authTakeService.currentUserValue.fullname_il != undefined){ 
            this.fullName = this.authTakeService.currentUserValue.fullname_il.split(" ")[0];
        }  
      }
    }
  
    if(this.cookieValue=='il'){
      this.textDir='rtl';
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    }
    else{
      this.textDir='ltr';
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    }
  }

  setLanguage(text: string, lang: string, flag: string,dir:string) {
    
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.textDir= dir ;
    this.languageService.setLanguage(lang);
    location.reload();

    // if(this.cookieValue=='il'){
    //   this.textDir='rtl';
    //   document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    //   location.reload();
    // }
    // else{
    //   this.textDir='ltr';
    //   document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    //   location.reload();
    // }
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }


  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }
  
  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else {
      this.authFackservice.logout();
    }
    this.router.navigate(['/account/login']);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }


  shops:any[];
  getAllShops(){
    this.apiService.getAllShops(this.authTakeService.currentUserValue.id).subscribe((shops) => {
      this.shops = shops;
    });
  }

  /**
   * search section
   */
  topSeachFocus(){
    this.getAllShops();
    this.isDropdownOpen = true;
  }

  topSearchChange(event:any){ 
    let searchText = event.target.value;
    this.searchResults= [];
    let filteredShops = this.shops.filter(s => s.name.indexOf(searchText) > -1).slice(0,3);
    if(filteredShops){
      
      filteredShops.forEach((shop,index) => {
        if(index == 0){
          this.searchResults.push({
            id : '',
            name: 'Shops',
            logo: 'bxs-store',
            type: 'shop',
            url: 'shops'
          })
        }
        this.searchResults.push({
          id : shop.id,
          name: shop.name,
          logo: shop.logo,
          type: 'shop',
          url: 'shops'
        })
      }) 
    }

    this.apiService.getAllProducts(null,null,null,null,3,searchText,this.authTakeService.currentUserValue.id).subscribe((products) => {
      let latestPResults = this.searchResults.filter(s => s.type != 'product'); 
      this.searchResults = latestPResults;
      if(products.data){
        products.data.forEach((pro,index) => {
          if(index == 0){
            this.searchResults.push({
              id : '',
              name: 'Products',
              logo: 'bxs-dish',
              type: 'product',
              url: 'products'
            })
          }
          this.searchResults.push({
            id : pro.id,
            name: pro.title,
            logo: pro.pictures,
            type: 'product',
            url: 'products'
          })
        }) 
      }
    });
    

    this.apiService
      .getAllBrands("", 0, 3, searchText)
      .subscribe((brand) => {
        let latestBResults = this.searchResults.filter(s => s.type != 'brand'); 
        this.searchResults = latestBResults;
        if(brand.data){
          brand.data.forEach((br,index) => {
            if(index == 0){
              this.searchResults.push({
                id : '',
                name: 'Brands',
                logo: 'bxs-food-menu',
                type: 'brand',
                url: 'brands'
              })
            }
            this.searchResults.push({
              id : br.id,
              name: br.title,
              logo: br.logo,
              type: 'brand',
              url: 'brands'
            })
          }) 
        }
      });


      this.apiService
      .getAllCategoryList("", 0, 3, searchText)
      .subscribe((Category) => {
        let latestCResults = this.searchResults.filter(s => s.type != 'category'); 
        this.searchResults = latestCResults;
        if(Category.data){
          Category.data.forEach((cat,index) => {
            if(index == 0){
              this.searchResults.push({
                id : '',
                name: 'Category',
                logo: 'bx-food-tag',
                type: 'category',
                url: 'category'
              })
            }
            this.searchResults.push({
              id : cat.id,
              name: cat.title,
              logo: cat.image,
              type: 'category',
              url: 'category'
            })
          }) 
        }
      });
  }

}
