import { Injectable } from "@angular/core";
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthtakeService } from "./core/services/authtake.service";

// import { User } from '../models/auth.models';

@Injectable({ providedIn: "root" })
export class ApiService {
 
  public static URL = "https://www.freshneasy.co.il/";  
	PHP_API_SERVER = "https://www.freshneasy.co.il/";
  public static IMAGE_URL = "https://www.freshneasy.co.il//uploads";
  public static NO_FOUND_IMAGE_URL =
    "https://www.freshneasy.co.il/templates/shoptik/img/no-preview.png";

    private headers: HttpHeaders;

  constructor(private http: HttpClient,public authTakeService : AuthtakeService) {
  }

  getLanguage(code) {
    return this.http.get<any>(`${this.PHP_API_SERVER}/api/v1/language/?where[code]=` +code);
  }

  getFeilds() {
    return this.http.get<any>(`${this.PHP_API_SERVER}/api/v1/fields/?where[id]= 150`);
  }

  getAllOrders() {
    // return this.http.get<any>(`/api/login`);
    let search = "";
    let sortby = "";
    let take = "";
    let skip = "";
    let wherec = "";
    
    return this.http.get<any>(
      `${this.PHP_API_SERVER}/api/v1/orders/?cursor=50&count=5000&search=` +
        search +
        `&sortby=` +
        sortby +
        `&take=` +
        take +
        `&skip=` +
        skip 
    );
  }
  getAllShops(iduser) {
    // return this.http.get<any>(`/api/login`);
    
    let sortby = "";
    let cursor = 0;
    let count = 50;
    let wherec = "";
    var searchArray = ["name" ];
    // if (search != "") {
    //   for (const key in searchArray) {
    //     wherec += `&like[${searchArray[key]}]=${search}`;
    //   }
    // }
    if (iduser) {
      if(this.authTakeService.currentUserValue.usergroup_id == 1){
        wherec += "" ;
      }
      else{
        wherec += "&where[iduser]=" + iduser;
      }
    }
    // return this.http.get<any>(`${this.PHP_API_SERVER}/api/v1/shops/?cursor=0&count=50&where[iduser]=1&sorting=name&order=asc`);//cursor=`+cursor+`&count=`+count);
    // return
    return this.http.get<any>(
      `${this.PHP_API_SERVER}/api/v1/shops/?cursor=0&count=50&sorting=name&order=asc`+ wherec 
    ); //cursor=`+cursor+`&count=`+count);
    // return this.http.get<any>(`${this.PHP_API_SERVER}/api/v1/shops/?cursor=`+cursor+`&count=`+count);
  }
  getAllProducts(
    shop_id,
    brandArr,
    catarr,
    cursor = 0,
    count = 50,
    search = "",iduser) 
    {
    
    let sortby = "";
    let wherec = "";
    var searchArray = ["title"];
    
    if (search != "") {
      for (const key in searchArray) {
        wherec += `&like[${searchArray[key]}]=${search}`;
      }
    }
    if (shop_id) {
      wherec += "&where[shop_id]=" + shop_id;
    }
    if (brandArr) {
      brandArr.forEach(function (value) {
        wherec += "&where[brand_id]=" + value;
      });
    }
    if (catarr) {
      catarr.forEach(function (value) {
        wherec += "&where[category_id]=" + value;
      });
    } 
    if (iduser) {
      if(this.authTakeService.currentUserValue.usergroup_id == 1){
        wherec += "" ;
      }
      else{
        wherec += "&where[iduser]=" + iduser;
      }
    }
    return this.http.get<any>(
      `${this.PHP_API_SERVER}/api/v1/products/?cursor=` +
        cursor +
        `&count=` +
        count +
        `&sorting=name&order=asc` +
        wherec
    );
  }
  ///*** Brands Action Start *****/
  getAllBrands(shop_id, cursor = 0, count = 50, search = "") {
    
    let sortby = "";
    let wherec = "";
    var searchArray = ["title", "shopname"];
    if (search != "") {
      for (const key in searchArray) {
        wherec += `&where[${searchArray[key]}]= ${search}`;
      }
    }
    if (shop_id) {
      wherec += "&where[shop_id]=" + shop_id;
    }
    return this.http.get<any>(
      `${this.PHP_API_SERVER}/api/v1/brands/?cursor=` +
        cursor +
        `&count=` +
        count +
        `&sorting=name&order=asc` +
        wherec
    );
  }

  uploadimg(file) {
    console.log("filesaaa", file);
    return this.http.post<any>(`${this.PHP_API_SERVER}uploadimg/`, file, {
      reportProgress: true,
      observe: "events",
    } );

    //return this.http.post(`${this.PHP_API_SERVER}uploadimg/`, file);
  }

  uploadBase64(file, title, type, name): Observable<any> {
   

    const byteCharacters = atob(file);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    var blob = new Blob(byteArrays);
    blob = blob.slice(0, blob.size, type)
    
    // Create form data
    const formData = new FormData();
    // Store form name as "file" with file data
    formData.append("img", blob,name);
    formData.append("title", title);

    return this.http.post(`${this.PHP_API_SERVER}uploadimg/`, formData);
  }

  upload(file, title): Observable<any> {
    // Create form data
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append("img", file, file.name);
    formData.append("title", title);

    // Make http post request over api
    // with formData as req
    return this.http.post(`${this.PHP_API_SERVER}uploadimg/`, formData);
  }

  addBrand(brand) {
    return this.http.post(`${this.PHP_API_SERVER}api/v1/brands/`, brand);
  }
  deleteBrand(brandId: any) {
    return this.http.delete(`${this.PHP_API_SERVER}api/v1/brands/${brandId}/`);
  }
  updateBrand(brand, id) {
    console.log("brand", brand);
    return this.http.put(`${this.PHP_API_SERVER}api/v1/brands/${id}/`, brand);
  }

  getOneBrand(brandId: any): Observable<any> {
    return this.http.get(`${this.PHP_API_SERVER}api/v1/brands/${brandId}/`);
  }

  ///*** Brands Action End *****/

  ///**** Product List Start *****/
  getAllProductList(shop_id, cursor = 0, count = 50, search = "") {
    let sortby = "";
    let wherec = "";
    var searchArray = ["title", "shopname"];
    if (search != "") {
      for (const key in searchArray) {
        wherec += `&where[${searchArray[key]}]= ${search}`;
      }
    }

    if (shop_id) {
      wherec += "&where[shop_id]=" + shop_id;
    }
    return this.http.get<any>(
      `${this.PHP_API_SERVER}/api/v1/products/?cursor=` +
        cursor +
        `&count=` +
        count +
        `&sorting=name&order=asc` +
        wherec
    );
  }

  addProduct(product) {
 
  //  return this.http.post(`${this.PHP_API_SERVER}api/v1/products/`, product);
    return this.http.post(`${this.PHP_API_SERVER}api/v1/products/`, product);
  }
  deleteProduct(productId: any) { 
    return this.http.delete(
      `${this.PHP_API_SERVER}api/v1/products/${productId}/`
    );
  }
  updateProduct(product, id) {
   
    return this.http.put(
      `${this.PHP_API_SERVER}api/v1/products/${id}/`,
      product
    );
  }

  getOneProduct(brandId: any): Observable<any> {
    return this.http.get(`${this.PHP_API_SERVER}api/v1/products/${brandId}/`);
  }
 
  addProductMultiple(products) {
     return this.http.post(`${this.PHP_API_SERVER}api/v1/products/`, products);
  }

  ///**** Product Action End *****/
  
  ///**** Product_addons Start ****/

  getAllProducts_addons(
  
    brandArr,
    catarr,
    cursor = 0,
    count = 50,
    search = "",) 
    {
    
    let sortby = "";
    let wherec = "";
    var searchArray = ["title"];
    
    if (search != "") {
      for (const key in searchArray) {
        wherec += `&like[${searchArray[key]}]=${search}`;
      }
    }
   
    if (brandArr) {
      brandArr.forEach(function (value) {
        wherec += "&where[brand_id]=" + value;
      });
    }
    if (catarr) {
      catarr.forEach(function (value) {
        wherec += "&where[category_id]=" + value;
      });
    } 
    
    return this.http.get<any>(
      `${this.PHP_API_SERVER}/api/v1/admin_addons/?cursor=` +
        cursor +
        `&count=` +
        count +
        `&sorting=name&order=asc` 
        
    );
  }
  getOneProduct_addons(products_addonsId: any): Observable<any> {
    return this.http.get(`${this.PHP_API_SERVER}api/v1/admin_addons/${products_addonsId}/`);
  }
  addProduct_addons(product_addons) {
    
    //  return this.http.post(`${this.PHP_API_SERVER}api/v1/products/`, product);
      return this.http.post(`${this.PHP_API_SERVER}api/v1/admin_addons/`, product_addons);
    }

    updateProduct_addons(product_addons, id) {
   
      return this.http.put(
        `${this.PHP_API_SERVER}api/v1/admin_addons/${id}/`,
        product_addons
      );
    }

    deleteProduct_addons(product_addonsId: any) { 
      return this.http.delete(
        `${this.PHP_API_SERVER}api/v1/admin_addons/${product_addonsId}/`
      );
    }

  ///**** Product_addons End ****/


  ///**** Shop_addOns  Start */
  getAllShop_addons(
  
  
    cursor = 0,
    count = 50,
    search = "",) 
    {
    
    let sortby = "";
    let wherec = "";
    var searchArray = ["title"];
    
    if (search != "") {
      for (const key in searchArray) {
        wherec += `&like[${searchArray[key]}]=${search}`;
      }
    }
   
   
    
    return this.http.get<any>(
      `${this.PHP_API_SERVER}/api/v1/shop_addons/?cursor=` +
        cursor +
        `&count=` +
        count +
        `&sorting=name&order=asc` 
        
    );
  }
  
  getOneShop_addons(shop_addonsId: any): Observable<any> {
    return this.http.get(`${this.PHP_API_SERVER}api/v1/shop_addons/${shop_addonsId}/`);
  }

  addShop_addons(shop_addons) {
    
    //  return this.http.post(`${this.PHP_API_SERVER}api/v1/products/`, product);
      return this.http.post(`${this.PHP_API_SERVER}api/v1/shop_addons/`, shop_addons);
  }

  updateShop_addons(shop_addons, id) {
   
    return this.http.put(
      `${this.PHP_API_SERVER}api/v1/shop_addons/${id}/`,
      shop_addons
    );
  }
  
  deleteShop_addons(shop_addonsId: any) { 
    return this.http.delete(
      `${this.PHP_API_SERVER}api/v1/shop_addons/${shop_addonsId}/`
    );
  }
  ///**** Shop_addOns End */

  ///**** Shop Action Start *****/
  getAllShopList(shop_id) {
    let search = "";
    let sortby = "";
    let cursor = 0;
    let count = 50;

    let wherec = "";
    if (shop_id) {
      wherec += "&where[shop_id]=" + shop_id;
    }
    return this.http.get<any>(
      `${this.PHP_API_SERVER}/api/v1/shops/?cursor=` +
        cursor +
        `&count=` +
        count +
        `&sorting=name&order=asc` +
        wherec
    );
  }

  addShop(shop) {
    return this.http.post(`${this.PHP_API_SERVER}api/v1/shops/`, shop);
  }
  deleteShop(shopId: any) {
    return this.http.delete(`${this.PHP_API_SERVER}api/v1/shops/${shopId}/`);
  }
  updateShop(shop, id) {
    return this.http.put(`${this.PHP_API_SERVER}api/v1/shops/${id}/`, shop);
  }

  updateShopStatus(id) { 
    this.getOneShop(id).subscribe((shop) =>{
     
      shop.status = shop.status == "1" || shop.status == 1 ? "0" : "1";
      this.http.put(`${this.PHP_API_SERVER}api/v1/shops/${id}/`, shop);
    });
  }
  
  addShop_Location(shops_locations) {
    return this.http.post(`${this.PHP_API_SERVER}api/v1/shops_locations/`, shops_locations);
  }
  deleteShopLocation(shopLocationId: any) {
    return this.http.delete(`${this.PHP_API_SERVER}api/v1/shops_locations/${shopLocationId}/`);
  }

  updateShop_Location(shopLocation, id, branchId) {
    let wherec = "";
    // if (branchId != 0 && branchId != null && branchId != undefined) {
    //   wherec += "&where[shop_branch_id]=" + branchId;
    // } 

    return this.http.put(`${this.PHP_API_SERVER}api/v1/shops_locations/${id}/`, shopLocation);
  }

  getShop_Location(id,branchId) { 
    let wherec = "";
    // if (branchId != 0 && branchId != null && branchId != undefined) {
    //   wherec += "&where[shop_branch_id]=" + branchId;
    // }
    if (branchId == null || branchId == undefined) {
      wherec += "&where[shop_branch_id]=0";
    }
    else{
      wherec += "&where[shop_branch_id]="+ branchId;
    }

    return this.http.get(`${this.PHP_API_SERVER}api/v1/shops_locations/?where[shops_id]=${id}${wherec}/`);
  }

  updateShop_Hours(shopHour, id) {
    let wherec = "";
    // if (branchId != 0 && branchId != null && branchId != undefined) {
    //   wherec += "&where[shop_branch_id]=" + branchId;
    // } 

    //return this.http.put(`${this.PHP_API_SERVER}api/v1/shops_hours/?where[shops_id]=${id}${wherec}/`, shopHour);
    return this.http.put(`${this.PHP_API_SERVER}api/v1/shops_hours/${id}/`, shopHour);
  }

  getShop_Hours(id,branchId) { 
    let wherec = "";
    // if (branchId != 0 && branchId != null && branchId != undefined) {
    //   wherec += "&where[shop_branch_id]=" + branchId;
    // }
    if (branchId == null || branchId == undefined) {
      wherec += "&where[shop_branch_id]=0";
    }
    else{
      wherec += "&where[shop_branch_id]="+ branchId;
    }

    return this.http.get(`${this.PHP_API_SERVER}api/v1/shops_hours/?where[shops_id]=${id}${wherec}`);
  }

  addShop_User(shopUser) {
    return this.http.post(`${this.PHP_API_SERVER}api/v1/shops_users/`, shopUser);
  }
  
  updateShop_Users(shopUsers, id, branchId) { 
    // let wherec = "";
    // if (branchId != 0 && branchId != null && branchId != undefined) {
    //   wherec += "&where[shop_branch_id]=" + branchId;
    // }
    return this.http.put(`${this.PHP_API_SERVER}api/v1/shops_users/${id}/`, shopUsers);
  }
  
  getShop_Users(id,branchId) {  
    let wherec = "";
    // if (branchId != 0 && branchId != null && branchId != undefined) {
    //   wherec += "&where[shop_branch_id]=" + branchId;
    // }
    if (branchId == null || branchId == undefined) {
      wherec += "&where[shop_branch_id]=0";
    }
    else{
      wherec += "&where[shop_branch_id]="+ branchId;
    }
    return this.http.get(`${this.PHP_API_SERVER}api/v1/shops_users/?cursor=0&count=50&sorting=name&order=asc&where[shops_id]=${id}${wherec}/`);
  }

  addShop_branch(shopBranch) {
    return this.http.post(`${this.PHP_API_SERVER}api/v1/shops_branch/`, shopBranch);
  }
  
  updateShop_branch(shopBranch) {
    return this.http.put(`${this.PHP_API_SERVER}api/v1/shops_branch/${shopBranch.id}/`, shopBranch);
  }
  
  getShop_branches(id) {
    return this.http.get(`${this.PHP_API_SERVER}api/v1/shops_branch/?cursor=0&count=50&sorting=name&order=asc&where[shops_id]=${id}`);
  }

  getOneShop(shopId: any): Observable<any> {
    return this.http.get(`${this.PHP_API_SERVER}api/v1/shops/${shopId}/`);
  }

  getLogin(id) {
    return this.http.get(`${this.PHP_API_SERVER}api/v1/login/${id}/`);
  }
              ///**** Shop Action End *****/
              ///**** DashBoard Action Start *****/
  getDashBoardProducts(){
    
    return this.http.get(`${this.PHP_API_SERVER}api/v1/products/?`);
  }

// getDashBoardData(){
    
//     return this.http.get(`${this.PHP_API_SERVER}api/v1/dashboard/`);
//   }
  
           ///**** Dashboard Action End *****/
  ///**** Category Action Start *****/
  getAllCategoryList(shop_id, cursor = 0, count = 50, search = "") {
    let sortby = "";
    let wherec = "";
    var searchArray = ["title", "shopname"];
    if (search != "") {
      for (const key in searchArray) {
        wherec += `&where[${searchArray[key]}]= ${search}`;
      }
    }
    if (shop_id) {
      wherec += "&where[shop_id]=" + shop_id;
    }
    return this.http.get<any>(
      `${this.PHP_API_SERVER}/api/v1/categories/?cursor=` +
        cursor +
        `&count=` +
        count +
        `&sorting=name&order=asc` +
        wherec
    );
  }

  addCategory(category) {
    return this.http.post(`${this.PHP_API_SERVER}api/v1/categories/`, category);
  }
  deleteCategory(categoryId: any) {
    return this.http.delete(
      `${this.PHP_API_SERVER}api/v1/categories/${categoryId}/`
    );
  }
  updateCategory(category, id) {
    return this.http.put(
      `${this.PHP_API_SERVER}api/v1/categories/${id}/`,
      category
    );
  }

  getOneCategory(categoryId: any): Observable<any> {
    return this.http.get(
      `${this.PHP_API_SERVER}api/v1/categories/${categoryId}/`
    );
  }

  ///**** Shop Action End *****/

  getAllCategories(shop_id) {
    let search = "";
    let sortby = "";
    let cursor = 0;
    let count = 50;

    let wherec = "";
    if (shop_id) {
      wherec += "&where[shop_id]=" + shop_id;
    }
    return this.http.get<any>(
      `${this.PHP_API_SERVER}/api/v1/categories/?cursor=` +
        cursor +
        `&count=` +
        count +
        `&sorting=name&order=asc` +
        wherec
    );
  }
  getProductDetails(productid) {
    return this.http.get<any>(
      `${this.PHP_API_SERVER}/api/v1/products/?cursor=0&count=1&where[id]=` +
        productid
    );
  }
  getpage(page, itemsPerPage) {
    const url = `https://api.instantwebtools.net/v1/passenger?page=${page}&size=${itemsPerPage}`;
    return this.http.get<any>(`${url}`);
  }

  // register(user: User) {
  // return this.http.post(`/users/register`, user);
  // }
}
