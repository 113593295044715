<footer class="footer ">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                fresh n'easy.
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    ©{{year}}  Powered by fresh n'easy
                </div>
            </div>
        </div>
    </div>
</footer>