import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "admin.menu_menu",
    isTitle: true,
  },
  {
    id: 2,
    label: "admin.menu_dashboards",
    icon: "bx-home-circle",
    badge: {
      variant: "info",
      text: "admin.menu_dashboards_badge",
    },
    subItems: [
      {
        id: 3,
        label: "admin.menu_default",
        link: "/dashboard",
        parentId: 2,
      },
      {
        id: 4,
        label: "admin.menu_saas",
        link: "/dashboards/saas",
        parentId: 2,
      },
      {
        id: 5,
        label: "admin.menu_crypto",
        link: "/dashboards/crypto",
        parentId: 2,
      },
      {
        id: 6,
        label: "admin.menu_blog",
        link: "/dashboards/blog",
        parentId: 2,
      },
    ],
  },
  {
    id: 7,
    isLayout: true,
  },
  {
    id: 8,
    label: "admin.menu_apps",
    isTitle: true,
  },
  {
    id: 9,
    label: "admin.menu_calendar",
    icon: "bx-calendar",
    link: "/calendar",
  },
  {
    id: 10,
    label: "admin.menu_chat",
    icon: "bx-chat",
    link: "/chat",
  },
  {
    id: 11,
    label: "admin.menu_filemanager",
    icon: "bx-file",
    link: "/filemanager",
    badge: {
      variant: "success",
      text: "admin.menu_filemanager_badge",
    },
  },
  {
    id: 12,
    label: "admin.menu_ecommerce",
    icon: "bx-store",
    subItems: [
      {
        id: 132,
        label: "admin.menu_brands",
        link: "/ecommerce/brands",
        parentId: 12,
      },{
        id: 129,
        label: "admin.menu_category",
        link: "/ecommerce/category",
        parentId: 12,
      },
    //  {
    //     id: 130,
    //     label: "admin.menu_product-list",
    //     link: "/ecommerce/product-list",
    //     parentId: 12,
    //   },
      {
        id: 13,
        label: "admin.menu_products",
        link: "/ecommerce/products",
        parentId: 12,
      },
      {
        id: 14,
        label: "admin.menu_product-detail",
        link: "/ecommerce/product-detail/1",
        parentId: 12,
      },
      {
        id: 131,
        label: "admin.menu_productsaddons",
        link: "/ecommerce/productsaddons",
        parentId: 12,
      },
      {
        id: 15,
        label: "admin.menu_oders",
        link: "/ecommerce/orders",
        parentId: 12,
      },
      {
        id: 16,
        label: "admin.menu_customers",
        link: "/ecommerce/customers",
        parentId: 12,
      },
      {
        id: 17,
        label: "admin.menu_cart",
        link: "/ecommerce/cart",
        parentId: 12,
      },
      {
        id: 18,
        label: "admin.menu_checkout",
        link: "/ecommerce/checkout",
        parentId: 12,
      },
      {
        id: 19,
        label: "admin.menu_shops",
        link: "/ecommerce/shops",
        parentId: 12,
      },
      // {
      //   id: 20,
      //   label: "admin.menu_add-product",
      //   link: "/ecommerce/add-product",
      //   parentId: 12,
      // },
    ],
  },
  {
    id: 21,
    label: "admin.menu_crypto",
    icon: "bx-bitcoin",
    subItems: [
      {
        id: 22,
        label: "admin.menu_wallet",
        link: "/crypto/wallet",
        parentId: 21,
      },
      {
        id: 23,
        label: "admin.menu_buy-sell",
        link: "/crypto/buy-sell",
        parentId: 21,
      },
      {
        id: 24,
        label: "admin.menu_exchange",
        link: "/crypto/exchange",
        parentId: 21,
      },
      {
        id: 25,
        label: "admin.menu_lending",
        link: "/crypto/lending",
        parentId: 21,
      },
      {
        id: 26,
        label: "admin.menu_orders",
        link: "/crypto/orders",
        parentId: 21,
      },
      {
        id: 27,
        label: "admin.menu_kyc-application",
        link: "/crypto/kyc-application",
        parentId: 21,
      },
      {
        id: 28,
        label: "admin.menu_crypto-ico-landing",
        link: "/crypto-ico-landing",
        parentId: 21,
      },
    ],
  },
  {
    id: 29,
    label: "admin.menu_email",
    icon: "bx-envelope",
    subItems: [
      {
        id: 30,
        label: "admin.menu_inbox",
        link: "/email/inbox",
        parentId: 29,
      },
      {
        id: 31,
        label: "admin.menu_reademail",
        link: "/email/read/1",
        parentId: 29,
      },
      {
        id: 32,
        label: "admin.menu_template",
        badge: {
          variant: "success",
          text: "admin.menu_email_list_template_badge",
        },
        parentId: 29,
        subItems: [
          {
            id: 33,
            label: "admin.menu_basic",
            link: "/email/basic",
            parentId: 32,
          },
          {
            id: 34,
            label: "admin.menu_alert",
            link: "/email/alert",
            parentId: 32,
          },
          {
            id: 35,
            label: "admin.menu_billing",
            link: "/email/billing",
            parentId: 32,
          },
        ],
      },
    ],
  },
  {
    id: 36,
    label: "admin.menu_invoices",
    icon: "bx-receipt",
    subItems: [
      {
        id: 37,
        label: "admin.menu_invoices-List",
        link: "/invoices/list",
        parentId: 36,
      },
      {
        id: 38,
        label: "admin.menu_invoices-detail",
        link: "/invoices/detail",
        parentId: 36,
      },
    ],
  },
  {
    id: 39,
    label: "admin.menu_projects",
    icon: "bx-briefcase-alt-2",
    subItems: [
      {
        id: 40,
        label: "admin.menu_project-grid",
        link: "/projects/grid",
        parentId: 38,
      },
      {
        id: 41,
        label: "admin.menu_project-list",
        link: "/projects/list",
        parentId: 38,
      },
      {
        id: 42,
        label: "admin.menu_project-overview",
        link: "/projects/overview",
        parentId: 38,
      },
      {
        id: 43,
        label: "admin.menu_project-create",
        link: "/projects/create",
        parentId: 38,
      },
    ],
  },
  {
    id: 44,
    label: "admin.menu_task",
    icon: "bx-task",
    subItems: [
      {
        id: 45,
        label: "admin.menu_task-list",
        link: "/tasks/list",
        parentId: 44,
      },
      {
        id: 46,
        label: "admin.menu_kanban",
        link: "/tasks/kanban",
        parentId: 44,
      },
      {
        id: 47,
        label: "admin.menu_create",
        link: "/tasks/create",
        parentId: 44,
      },
    ],
  },
  {
    id: 48,
    label: "admin.menu_contacts",
    icon: "bxs-user-detail",
    subItems: [
      {
        id: 49,
        label: "admin.menu_contacts-grid",
        link: "/contacts/grid",
        parentId: 48,
      },
      {
        id: 50,
        label: "admin.menu_user-list",
        link: "/contacts/list",
        parentId: 48,
      },
      {
        id: 51,
        label: "admin.menu_contacts-profile",
        link: "/contacts/profile",
        parentId: 48,
      },
    ],
  },
  {
    id: 52,
    label: "admin.menu_blog",
    icon: "bx-file",
    badge: {
      variant: "success",
      text: "admin.menu_email_list_template_badge",
    },
    subItems: [
      {
        id: 53,
        label: "admin.menu_blog-list",
        link: "/blog/list",
        parentId: 52,
      },
      {
        id: 54,
        label: "admin.menu_blog-grid",
        link: "/blog/grid",
        parentId: 52,
      },
      {
        id: 55,
        label: "admin.menu_blog-detail",
        link: "/blog/detail",
        parentId: 52,
      },
    ],
  },
  {
    id: 56,
    label: "admin.menu_pages",
    isTitle: true,
  },
  {
    id: 57,
    label: "admin.menu_authentication",
    icon: "bx-user-circle",
    badge: {
      variant: "success",
      text: "admin.authentication_badge",
    },
    subItems: [
      {
        id: 58,
        label: "admin.menu_login",
        link: "/account/login",
        parentId: 57,
      },
      {
        id: 59,
        label: "admin.menu_login2",
        link: "/account/login-2",
        parentId: 57,
      },
      {
        id: 60,
        label: "admin.menu_register",
        link: "/account/signup",
        parentId: 57,
      },
      {
        id: 61,
        label: "admin.menu_register2",
        link: "/account/signup-2",
        parentId: 57,
      },
      {
        id: 62,
        label: "admin.menu_reset-password",
        link: "/account/reset-password",
        parentId: 57,
      },
      {
        id: 63,
        label: "admin.menu_reset-password2",
        link: "/account/recoverpwd-2",
        parentId: 57,
      },
      {
        id: 64,
        label: "admin.menu_lock-screen",
        link: "/pages/lock-screen-1",
        parentId: 57,
      },
      {
        id: 65,
        label: "admin.menu_lock-screen2",
        link: "/pages/lock-screen-2",
        parentId: 57,
      },
      {
        id: 66,
        label: "admin.menu_confirm-mail",
        link: "/pages/confirm-mail",
        parentId: 57,
      },
      {
        id: 67,
        label: "admin.menu_confirm-mail-2",
        link: "/pages/confirm-mail-2",
        parentId: 57,
      },
      {
        id: 68,
        label: "admin.menu_email-verification",
        link: "/pages/email-verification",
        parentId: 57,
      },
      {
        id: 69,
        label: "admin.menu_email-verification2",
        link: "/pages/email-verification-2",
        parentId: 57,
      },
      {
        id: 70,
        label: "admin.menu_two-step-verification",
        link: "/pages/two-step-verification",
        parentId: 57,
      },
      {
        id: 71,
        label: "admin.menu_two-step-verification2",
        link: "/pages/two-step-verification-2",
        parentId: 57,
      },
    ],
  },
  {
    id: 72,
    label: "admin.menu_utility",
    icon: "bx-file",
    subItems: [
      {
        id: 73,
        label: "admin.menu_starter",
        link: "/pages/starter",
        parentId: 72,
      },
      {
        id: 74,
        label: "admin.menu_maintenance",
        link: "/pages/maintenance",
        parentId: 72,
      },
      {
        id: 74,
        label: "admin.menu_comming_soon",
        link: "/pages/coming-soon",
        parentId: 72,
      },
      {
        id: 75,
        label: "admin.menu_timeline",
        link: "/pages/timeline",
        parentId: 72,
      },
      {
        id: 76,
        label: "admin.menu_faqs",
        link: "/pages/faqs",
        parentId: 72,
      },
      {
        id: 77,
        label: "admin.menu_pricing",
        link: "/pages/pricing",
        parentId: 72,
      },
      {
        id: 78,
        label: "admin.menu_error404",
        link: "/pages/404",
        parentId: 72,
      },
      {
        id: 79,
        label: "admin.menu_error500",
        link: "/pages/500",
        parentId: 72,
      },
    ],
  },
  {
    id: 80,
    label: "admin.menu_components",
    isTitle: true,
  },
  {
    id: 81,
    label: "admin.menu_uielements",
    icon: "bx-tone",
    subItems: [
      {
        id: 82,
        label: "admin.menu_alert",
        link: "/ui/alerts",
        parentId: 81,
      },
      {
        id: 83,
        label: "admin.menu_buttons",
        link: "/ui/buttons",
        parentId: 81,
      },
      {
        id: 84,
        label: "admin.menu_cards",
        link: "/ui/cards",
        parentId: 81,
      },
      {
        id: 85,
        label: "admin.menu_carousel",
        link: "/ui/carousel",
        parentId: 81,
      },
      {
        id: 86,
        label: "admin.menu_dropdowns",
        link: "/ui/dropdowns",
        parentId: 81,
      },
      {
        id: 87,
        label: "admin.menu_grid",
        link: "/ui/grid",
        parentId: 81,
      },
      {
        id: 88,
        label: "admin.menu_images",
        link: "/ui/images",
        parentId: 81,
      },
      {
        id: 89,
        label: "admin.menu_modals",
        link: "/ui/modals",
        parentId: 81,
      },
      {
        id: 90,
        label: "admin.menu_rangeslider",
        link: "/ui/rangeslider",
        parentId: 81,
      },
      {
        id: 91,
        label: "admin.menu_progressbar",
        link: "/ui/progressbar",
        parentId: 81,
      },
      {
        id: 92,
        label: "admin.menu_sweet-alert",
        link: "/ui/sweet-alert",
        parentId: 81,
      },
      {
        id: 93,
        label: "admin.menu_tabs-accordions",
        link: "/ui/tabs-accordions",
        parentId: 81,
      },
      {
        id: 94,
        label: "admin.menu_typography",
        link: "/ui/typography",
        parentId: 81,
      },
      {
        id: 95,
        label: "admin.menu_videos",
        link: "/ui/video",
        parentId: 81,
      },
      {
        id: 96,
        label: "admin.menu_general",
        link: "/ui/general",
        parentId: 81,
      },
      {
        id: 97,
        label: "admin.menu_colors",
        link: "/ui/colors",
        parentId: 81,
      },
      {
        id: 98,
        label: "admin.menu_image-croppper",
        link: "/ui/image-crop",
        parentId: 81,
      },
    ],
  },
  {
    id: 99,
    label: "admin.menu_forms",
    icon: "bxs-eraser",
    badge: {
      variant: "danger",
      text: "admin.menu_forms_badge",
    },
    subItems: [
      {
        id: 100,
        label: "admin.menu_elements",
        link: "/form/elements",
        parentId: 99,
      },
      {
        id: 101,
        label: "admin.menu_layouts",
        link: "/form/layouts",
        parentId: 99,
      },
      {
        id: 102,
        label: "admin.menu_forms-validatons",
        link: "/form/validation",
        parentId: 99,
      },
      {
        id: 103,
        label: "admin.menu_advanced",
        link: "/form/advanced",
        parentId: 99,
      },
      {
        id: 104,
        label: "admin.menu_editiors",
        link: "/form/editor",
        parentId: 99,
      },
      {
        id: 105,
        label: "admin.menu_fileuploads",
        link: "/form/uploads",
        parentId: 99,
      },
      {
        id: 106,
        label: "admin.menu_repeater",
        link: "/form/repeater",
        parentId: 99,
      },
      {
        id: 107,
        label: "admin.menu_wizard",
        link: "/form/wizard",
        parentId: 99,
      },
      {
        id: 108,
        label: "admin.menu_mask",
        link: "/form/mask",
        parentId: 99,
      },
    ],
  },
  {
    id: 109,
    icon: "bx-list-ul",
    label: "admin.menu_tabel",
    subItems: [
      {
        id: 110,
        label: "admin.menu_tabels-basic",
        link: "/tables/basic",
        parentId: 109,
      },
      {
        id: 111,
        label: "admin.menu_tabels-advanced",
        link: "/tables/advanced",
        parentId: 109,
      },
    ],
  },
  {
    id: 112,
    icon: "bxs-bar-chart-alt-2",
    label: "admin.menu_charts",
    subItems: [
      {
        id: 113,
        label: "admin.menu_charts-apex",
        link: "/charts/apex",
        parentId: 112,
      },
      {
        id: 114,
        label: "admin.menu_charts-chartjs",
        link: "/charts/chartjs",
        parentId: 112,
      },
      {
        id: 115,
        label: "admin.menu_chartlist",
        link: "/charts/chartist",
        parentId: 112,
      },
      {
        id: 116,
        label: "admin.menu_charts-echarts",
        link: "/charts/echart",
        parentId: 112,
      },
    ],
  },
  {
    id: 117,
    label: "admin.menu_icons",
    icon: "bx-aperture",
    subItems: [
      {
        id: 118,
        label: "admin.menu_boxicons",
        link: "/icons/boxicons",
        parentId: 117,
      },
      {
        id: 119,
        label: "admin.menu_materialdesign",
        link: "/icons/materialdesign",
        parentId: 117,
      },
      {
        id: 120,
        label: "admin.menu_dripicons",
        link: "/icons/dripicons",
        parentId: 117,
      },
      {
        id: 121,
        label: "admin.menu_fontawesome",
        link: "/icons/fontawesome",
        parentId: 117,
      },
    ],
  },
  {
    id: 122,
    label: "admin.menu_maps",
    icon: "bx-map",
    subItems: [
      {
        id: 123,
        label: "admin.menu_googlemap",
        link: "/maps/google",
        parentId: 122,
      },
    ],
  },
  {
    id: 124,
    label: "admin.menu_multilevel",
    icon: "bx-share-alt",
    subItems: [
      {
        id: 125,
        label: "admin.menu_level1.1",
        parentId: 124,
      },
      {
        id: 126,
        label: "admin.menu_level1.2",
        parentId: 124,
        subItems: [
          {
            id: 127,
            label: "admin.menu_level2.1",
            parentId: 126,
          },
          {
            id: 128,
            label: "admin.menu_level2.2",
            parentId: 126,
          },
        ],
      },
    ],
  },
];
