import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'src/app/api.service';
import { User,UserGroupType } from '../models/auth.models';

@Injectable({ providedIn: 'root' })
export class AuthtakeService {
  user: User;
  PHP_API_SERVER = "https://www.freshneasy.co.il/";
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
 
  constructor(private http: HttpClient) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User { 
      return this.currentUserSubject.value;
  }


  public get userGroupEnum(): UserGroupType {
      if(this.currentUserSubject.value){
        return UserGroupType[this.currentUserSubject.value.usergroup];
      }
      return UserGroupType['User'];
  }


  login(loginData){
    
    if(sessionStorage.getItem("token")){
      let httpHeader = new HttpHeaders({'x-auth-token':sessionStorage.getItem("token")});
      return this.http.post<any>(`${this.PHP_API_SERVER}api/v1/auth/`, loginData, {headers : httpHeader})
              .pipe(map(res  => {
                if (res) {
                    if((res.error == null || res.error == undefined) && res.id != null && res.id != undefined){
                      if(res.usergroup_id == UserGroupType.administrators || res.usergroup_id == UserGroupType.shop_owner )
                     { 
                      localStorage.setItem('currentUser', JSON.stringify(res)); 
                      this.currentUserSubject.next(res);
                     } 
                     else{
                      res.error = "Unauthorized Access!!";
                     }
                    }
                }
                return res;
            })); 
    }
    else{
      this.gettoken(loginData);
    }
  }

  getTokenForLogin(){
    return this.http.get<any>(`${this.PHP_API_SERVER}/api/v1/token/`);
  }

  gettoken(loginData){
     this.http.get<any>(`${this.PHP_API_SERVER}/api/v1/token/`).subscribe((response: any) => {
      sessionStorage.setItem("token",response);
      this.login(loginData);
    });
  }


  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

}
