import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/api.service';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public languages: string[] = ['en', 'il'];
  //public languages: string[] = ['en', 'es', 'de', 'it', 'ru'];

  constructor(public translate: TranslateService, private cookieService: CookieService,
    private apiService:ApiService) {
    this.getLanguagesData();

    let browserLang;
    this.translate.addLangs(this.languages);
    if (this.cookieService.check('lang')) {
      browserLang = this.cookieService.get('lang');
    }
    else {
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|il/) ? browserLang : 'en');
    //translate.use(browserLang.match(/en|es|de|it|ru/) ? browserLang : 'en');
  } 

  public setLanguage(lang) {
    this.translate.use(lang);
    this.cookieService.set('lang', lang);
  }

  public getLanguagesData(){
    //translate.setTranslation("il",{"darshan" : "pqr" }, true);
    this.apiService.getLanguage("en").subscribe(res =>{
      let enJson = {};
      res.forEach(element => {
        enJson[element["ckey"]] = element["value"];
      });
      this.translate.setTranslation("en",enJson, true);
    });
    this.apiService.getLanguage("il").subscribe(res =>{
      let ilJson = {};
      res.forEach(element => {
        ilJson[element["ckey"]] = element["value"];
      });
      this.translate.setTranslation("il",ilJson, true);
    });
  }
}
