export class User {
    id: number;
    username: string;
    password: string;
    firstName?: string;
    lastName?: string;
    token?: string;
    email: string;
    fullname:string;
    fullname_il:string;
    avatar:string;
    usergroup_id : number;
    usergroup : string;
}




export enum UserGroupType {
  administrators	 = 1,
  shop_owner = 9,
  guests = 3,
 
  
}